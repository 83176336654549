@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Roboto:wght@400;500&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
html,
body,
#root,
.App {
  height: 100% !important;
  font-family: 'Roboto';
}

.react-tags {
  background-color: #fff;
  border: 1px solid #d1d1d1 !important;
  border-radius: 6px !important;
}

.react-time-picker__wrapper {
  padding: 8px !important;
  border-radius: 4px !important;
}

.ql-editor {
  min-height: 200px;
}
